// statically loaded
const importAll = (r) => r.keys().map(r)


importAll(require.context('../../images/dividend/', true, /\.(ico|png|jpe?g|svg)$/));

import EventManager from '../../classes/EventManager';
window.EventManager = EventManager;

// styles needed for initial page load
import '../../bootstrap'
import '../../styles'
import '../../stylesheets/dividend/home.styles'
import '../../stylesheets/smooth-table'

// dynamically loaded
import(
  /* webpackChunkName: "home-pack-loader" */
  `../../packLoader/dividend/home`
)
import(
  /* webpackChunkName: "stimulus-controllers" */
  'controllers'
)
